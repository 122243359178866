import axios from "axios";
import { getDefaultRestUrl } from "./config/config";

export const apiAddTranslationKey = (key) => {
  return axios.post(getDefaultRestUrl() + "/my-office/dictionary", { key });
};

export const apiGetTranslations = (lang) => {
  return axios.get(getDefaultRestUrl() + "/my-office/dictionary", { params: { lang: "cs" } });
};

export const apiTryLogin = async (username, password) => {
  const result = axios.post(getDefaultRestUrl() + "/my-office/auth");
  console.log("LOGIN_RESULRT");
  console.log(result);
};
