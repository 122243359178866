import { useState } from "react";
import Layout from "./components/Layout";
import LoginForm from "./components/LoginForm";
import LoginHead from "./components/LoginHead";

const App = () => {
  const [dataLoaded] = useState(true);

  // useEffect(() => {
  //   apiGetTranslations(getLang()).then((result) => {
  //     console.log("DICTIONARY");
  //     console.log(result.data.data.dictionary);
  //     setDictionary(result.data.data.dictionary);
  //     setDataLoaded(true);
  //   });
  // }, [dataLoaded]);

  return (
    <Layout>
      {!dataLoaded ? (
        <div>loading data</div>
      ) : (
        <>
          <LoginHead />
          <LoginForm />
        </>
      )}
    </Layout>
  );
};

export default App;
