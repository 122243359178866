import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ValidUserContextProvider } from "./authCheck";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { getAppLanguage } from "./getAppLanguage";
import { setDictionary, setLang } from "./store/store";
import { loadTranslations } from "./loadTranslations";

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ValidUserContextProvider>
          <App />
        </ValidUserContextProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

const main = async () => {
  // get app language
  const lang = getAppLanguage();
  setLang(lang);
  // load translations
  const dictionary = await loadTranslations(lang);
  setDictionary(dictionary);
  // render App
  renderApp();
  reportWebVitals();
};

main();
