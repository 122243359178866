let data = {};

// const setStoreData = (d) => {
//   data = d;
// };

export const setDictionary = (dictionary) => {
  data.dictionary = dictionary;
};

export const getDictionary = () => {
  return data.dictionary;
};

export const setLang = (lang) => {
  data.lang = lang;
};

export const getLang = () => {
  return data.lang;
};

//const getStore = () => data;
