import { useTranslation } from "../translator/useTranslation";
import classes from "./LoginHead.module.scss";

function LoginHead() {
  const { t } = useTranslation();
  return (
    <div>
      <title className={classes.loginTitle}>{t("tr_login_title")}</title>
      <div>{t("tr_please_fill_credentials")}</div>
    </div>
  );
}

export default LoginHead;
