import { apiAddTranslationKey } from "../api";
import { getDictionary } from "../store/store";

// export const setDictionary = (language, dict) => {
//   if (language.length !== 2) {
//     throw new Error("Languge code must be 2 character lengt.");
//   }
//   console.log("SETTING UP DICTIONARY");
//   dictionary = dict;
//   // lang = language;
// };

export const useTranslation = () => {
  return {
    t: (key) => {
      const dictionary = getDictionary();
      if (!dictionary) {
        throw new Error("Dictionary is not loaded.");
      }
      if (dictionary[key]) {
        return dictionary[key];
      } else {
        apiAddTranslationKey(key);
        return `Missing translation for key "${key}"`;
      }
    },
  };
};
